import { Grid } from '@material-ui/core';
import ClearButton from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleDatePickerInput from 'components/inputs/date-input/date-picker/simple-date-picker-input';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import {
    TransfeeraExternalStatementFilterRequest,
    defaultTransfeeraExternalStatementFilters,
} from 'shared/external/payment-accounts-strategy/statement/components/transfeera/transfeera-external-statement.model';

import { TransfeeraConstants } from 'shared/external/constants/transfeera-statment-constant';
import './transfeera-statement-filters-modal.scss';

export type TransfeeraStatementFiltersType = 'filters' | 'date';

interface TransfeeraStatementFiltersModalProps {
    onClose: () => void;
    modalFilter: TransfeeraStatementFiltersType;
    openModal: boolean;
    title: string;
    filterActive: TransfeeraExternalStatementFilterRequest;
    setFilterActive: (value: TransfeeraExternalStatementFilterRequest) => void;
    setPage: (value: number) => void;
    resetInfiniteScrollListing: () => void;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-statement.filters.inputs';

const DISABLED_MODAL_BUTTON_FUNCTION_RECORD: Record<
    TransfeeraStatementFiltersType,
    (filter: TransfeeraExternalStatementFilterRequest) => boolean
> = {
    filters: filter => _.isEqual(filter, defaultTransfeeraExternalStatementFilters),
    date: filter => !filter.initialDate && !filter.endDate,
};

const TransfeeraStatementFiltersModal = (props: TransfeeraStatementFiltersModalProps) => {
    const { filterActive, modalFilter, onClose, openModal, resetInfiniteScrollListing, setFilterActive, setPage, title } = props;

    const { t } = useTranslation();

    const [filterRequest, setFilterRequest] = useState<TransfeeraExternalStatementFilterRequest>(filterActive);

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    if (!openModal) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(TransfeeraConstants.STATEMENTS_PAGINATION_FIRST_INDEX);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<TransfeeraExternalStatementFilterRequest>) => {
        setFilterRequest(ps => ({ ...ps, ...value }));
    };

    const handleClearFilter: Record<TransfeeraStatementFiltersType, () => void> = {
        filters: () => {
            resetInfiniteScrollListing();
            setFilterActive(defaultTransfeeraExternalStatementFilters);
            setFilterRequest(defaultTransfeeraExternalStatementFilters);
        },
        date: () => {
            resetInfiniteScrollListing();
            setFilterActive({
                ...filterActive,
                initialDate: defaultTransfeeraExternalStatementFilters.initialDate,
                endDate: defaultTransfeeraExternalStatementFilters.endDate,
            });
            setFilterRequest({
                ...filterRequest,
                initialDate: defaultTransfeeraExternalStatementFilters.initialDate,
                endDate: defaultTransfeeraExternalStatementFilters.endDate,
            });
        },
    };

    const DateFilter: JSX.Element = (
        <>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.initialDate.label`)}
                    placeholder={t(`${I18N_PREFIX}.initialDate.label`)}
                    value={filterRequest?.initialDate}
                    onChange={initialDate => handleChange({ initialDate })}
                    maxDate={filterRequest.endDate}
                    externalUpdate
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.endDate.label`)}
                    placeholder={t(`${I18N_PREFIX}.endDate.label`)}
                    value={filterRequest?.endDate}
                    onChange={endDate => handleChange({ endDate })}
                    minDate={filterRequest.initialDate}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        openModal && (
            <Modal isOpen toggle={onClose}>
                <div className="transfeera-statement-modal-filter">
                    <div className="transfeera-statement-modal-filter--header">
                        <label className="transfeera-statement-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: <>{DateFilter}</>,
                                    date: DateFilter,
                                } as Record<TransfeeraStatementFiltersType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>

                    <div className="transfeera-statement-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default TransfeeraStatementFiltersModal;
