import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SendIcon from '@material-ui/icons/Send';
import BackDrop from 'components/backdrop/backdrop';
import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import Loading from 'components/loading/loading';
import ScdBatchOperationsHeader, {
    BatchOperationSortableProperties,
} from 'entities/batch/batch-detail-operations/components/batch-operations-header/batch-operations-header';
import BatchOperationsList from 'entities/batch/batch-detail-operations/components/batch-operations-list/batch-operations-list';
import BatchGenerateCnabModal from 'entities/batch/components/batch-generate-cnab-modal/batch-generate-cnab-modal';
import RelocateOperationBatchModal from 'entities/batch/components/batch-relocate-operation-modal/batch-relocate-operation-modal';
import BatchRelocateSuccessModal from 'entities/batch/components/batch-relocate-sucess-modal/batch-relocate-sucess-modal';
import BatchReportModal from 'entities/batch/components/batch-report-modal/batch-report-modal';
import BatchModalAction, { BatchModalActionOptions } from 'entities/batch/components/modal-action/modal-action';
import BatchModalCloseDetails from 'entities/batch/components/modal-close-details/modal-close-details';
import { BatchStatus } from 'model/enums/batch-status';
import { CnabTemplate } from 'model/enums/cnab-template';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ScdImportedOperationBatch } from 'model/imported-operation';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { archiveBatchOperationResetState } from 'reducer/batch/archive-batch-operation/actions';
import { closeBatchResetState } from 'reducer/batch/close-batch/actions';
import { createBatchToRelocateResetState } from 'reducer/batch/create-batch-to-relocate/actions';
import { deleteBatchOperationResetState } from 'reducer/batch/delete-batch-operation/actions';
import { detailBatchRequest, detailBatchResetState } from 'reducer/batch/detail-batch/actions';
import { exportBatchResetState } from 'reducer/batch/export-batch/actions';
import { relocateToExistingBatchResetState } from 'reducer/batch/relocate-operation-to-existing-batch/actions';
import { sendBatchCcbDocumentsResetState } from 'reducer/batch/send-ccb-documents/actions';
import { sendBatchCnabDocumentResetState } from 'reducer/batch/send-cnab-document/actions';
import {
    useArchiveBatchOperationState,
    useCloseBatchState,
    useCreateBatchToRelocate,
    useDeleteBatchOperationState,
    useDetailBatchState,
    useExportBatchState,
    useImportedOperationBatchState,
    useImportedOperationReexportState,
    useRelocateToExistingBatchState,
    useRootDispatch,
    useSendBatchCcbDocumentsState,
    useSendBatchCnabDocumentState,
} from 'reducer/hooks';
import { importedOperationsBatchRequest, importedOperationsBatchResetState } from 'reducer/imported-operation/batch/actions';
import { Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import { useToast } from 'shared/hooks/use-toast';
import Pagination from 'shared/pagination/pagination';
import './batch-detail-operations-legacy.scss';

type ScdBatchDetailLegacyProps = RouteComponentProps<{ batchId: string }>;

export interface BatchSortPageable {
    batchId: number;
    pageable: Pageable;
}

const LIST_OPERATIONS_PAGE_SIZE = 8;

const I18N_PREFIX = 'pages.scd.batch';

const BatchDetailOperationsLegacy = (props: ScdBatchDetailLegacyProps) => {
    const { t } = useTranslation();
    const [batchId] = useState<number>(Number(props.match.params.batchId));
    const [sortedProperty, setSortedProperty] = useState<BatchOperationSortableProperties | undefined>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [page, setPage] = useState<number>(0);
    const [modalAction, setModalAction] = useState<BatchModalActionOptions | undefined>(undefined);

    const [isOpenReportModal, setIsOpenReportModal] = useState<boolean>(false);
    const [operation, setOperation] = useState<ScdImportedOperationBatch | undefined>(undefined);
    const [isOpenGenerateCnabModal, setIsOpenGenerateCnabModal] = useState<boolean>(false);
    const [isOpenRelocateModal, setIsOpenRelocateModal] = useState<boolean>(false);
    const [modalCloseDetails, setModalCloseDetails] = useState<boolean>(false);

    const { toastLoading, closeToast, toastSuccess, toastError } = useToast();

    const { batch, status: batchStatus } = useDetailBatchState();
    const { batchOperations, status: batchOperationsStatus } = useImportedOperationBatchState();
    const { status: closeBatchStatus } = useCloseBatchState();
    const { status: deletedBatchOperationStatus } = useDeleteBatchOperationState();
    const { status: exportBatchStatus } = useExportBatchState();
    const { reexportedOperation, status: reexportOperationStatus } = useImportedOperationReexportState();
    const { status: sendCcbDocumentsStatus } = useSendBatchCcbDocumentsState();
    const { status: sendCnabDocumentStatus } = useSendBatchCnabDocumentState();
    const { createdBatchToRelocate, status: createBatchStatus } = useCreateBatchToRelocate();
    const { relocatedBatch, status: relocatedBatchStatus } = useRelocateToExistingBatchState();
    const { status: archiveOperationStatus } = useArchiveBatchOperationState();

    const dispatch = useRootDispatch();

    const sortPageable = useCallback((): BatchSortPageable => {
        const _pageable = {
            page: page,
            size: LIST_OPERATIONS_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},createdDate,${sortOrder}` : '',
        };

        return {
            pageable: _pageable,
            batchId: batchId,
        };
    }, [sortedProperty, sortOrder, page, batchId]);

    const handleOpenGenerateCnabModal = () => {
        if (!batch?.cnabTemplate) {
            toastError(t(`${I18N_PREFIX}.generate-cnab.toast.${batch?.cnabTemplate}`));
            return;
        }

        if (batch?.cnabTemplate === CnabTemplate.NO_CNAB) {
            toastError(t(`${I18N_PREFIX}.generate-cnab.toast.${batch?.cnabTemplate}`));
            return;
        }

        setIsOpenGenerateCnabModal(true);
    };

    const handleDetailBatchRequest = useCallback(() => {
        dispatch(detailBatchRequest(batchId));
    }, [dispatch, batchId]);

    useEffect(() => {
        handleDetailBatchRequest();
    }, [handleDetailBatchRequest]);

    useEffect(() => {
        if (batchStatus !== HttpRequestStatus.SUCCESS) return;

        dispatch(importedOperationsBatchRequest(sortPageable()));

        return () => {
            dispatch(importedOperationsBatchResetState());
        };
    }, [dispatch, batchStatus, sortPageable]);

    const handleTryAgain = () => {
        if (batchStatus !== HttpRequestStatus.SUCCESS) return;

        dispatch(importedOperationsBatchRequest(sortPageable()));
    };

    useEffect(() => {
        return () => {
            dispatch(detailBatchResetState());
        };
    }, [dispatch, batchId]);

    useEffect(() => {
        if (closeBatchStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (closeBatchStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalAction(undefined);
            dispatch(closeBatchResetState());
            handleDetailBatchRequest();
        }
        if (closeBatchStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            setModalAction(undefined);
            dispatch(closeBatchResetState());
            handleDetailBatchRequest();
            toastSuccess(t(`${I18N_PREFIX}.close-batch.toast.success`));
        }
    }, [closeBatchStatus, toastLoading, closeToast, handleDetailBatchRequest, toastSuccess, t, dispatch]);

    useEffect(() => {
        if (deletedBatchOperationStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (deletedBatchOperationStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalAction(undefined);
            dispatch(deleteBatchOperationResetState());
        }
        if (deletedBatchOperationStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            setModalAction(undefined);
            dispatch(deleteBatchOperationResetState());
            handleDetailBatchRequest();
            toastSuccess(t(`${I18N_PREFIX}.remove-operation.toast.success`));
        }
    }, [deletedBatchOperationStatus, toastLoading, closeToast, handleDetailBatchRequest, toastSuccess, t, dispatch]);

    useEffect(() => {
        if (exportBatchStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (exportBatchStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalAction(undefined);
            dispatch(exportBatchResetState());
        }
        if (exportBatchStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            setModalAction(undefined);
            dispatch(exportBatchResetState());
            handleDetailBatchRequest();
            toastSuccess(t(`${I18N_PREFIX}.export-webcred.toast.success`));
        }
    }, [exportBatchStatus, toastLoading, closeToast, handleDetailBatchRequest, toastSuccess, t, dispatch]);

    useEffect(() => {
        if (reexportOperationStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (reexportOperationStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalAction(undefined);
        }

        if (reexportedOperation?.hasError) {
            closeToast();
            setModalAction(undefined);
            toastError(
                reexportedOperation?.responseMessage
                    ? reexportedOperation.responseMessage
                    : t(`${I18N_PREFIX}.reexport-webcred.toast.error`)
            );
            return;
        }

        if (reexportOperationStatus === HttpRequestStatus.SUCCESS && !reexportedOperation?.hasError) {
            closeToast();
            setModalAction(undefined);
            handleDetailBatchRequest();
            toastSuccess(t(`${I18N_PREFIX}.reexport-webcred.toast.success`));
        }
    }, [reexportOperationStatus, toastLoading, closeToast, handleDetailBatchRequest, toastSuccess, t, reexportedOperation, toastError]);

    useEffect(() => {
        if (sendCcbDocumentsStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (sendCcbDocumentsStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalAction(undefined);
            dispatch(sendBatchCcbDocumentsResetState());
        }
        if (sendCcbDocumentsStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            setModalAction(undefined);
            dispatch(sendBatchCcbDocumentsResetState());
            toastSuccess(t(`${I18N_PREFIX}.send-ccb-documents.toast.success`));
        }
    }, [sendCcbDocumentsStatus, dispatch, toastLoading, closeToast, toastSuccess, t]);

    useEffect(() => {
        if (sendCnabDocumentStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (sendCnabDocumentStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalAction(undefined);
            dispatch(sendBatchCnabDocumentResetState());
        }
        if (sendCnabDocumentStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            setModalAction(undefined);
            dispatch(sendBatchCnabDocumentResetState());
            toastSuccess(t(`${I18N_PREFIX}.send-cnab-document.toast.success`));
        }
    }, [sendCnabDocumentStatus, dispatch, toastLoading, closeToast, toastSuccess, t]);

    useEffect(() => {
        if (createBatchStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [createBatchStatus, toastLoading]);

    useEffect(() => {
        if (createBatchStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
        setIsOpenRelocateModal(false);
        dispatch(createBatchToRelocateResetState());
    }, [createBatchStatus, closeToast, dispatch]);

    useEffect(() => {
        if (createBatchStatus !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        setIsOpenRelocateModal(false);
        handleDetailBatchRequest();
        toastSuccess(t(`${I18N_PREFIX}.relocate-operation.toast.success`));
    }, [createBatchStatus, closeToast, dispatch, t, toastSuccess, handleDetailBatchRequest]);

    useEffect(() => {
        if (relocatedBatchStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [relocatedBatchStatus, toastLoading]);

    useEffect(() => {
        if (relocatedBatchStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
        setIsOpenRelocateModal(false);
        dispatch(relocateToExistingBatchResetState());
    }, [relocatedBatchStatus, closeToast, dispatch]);

    useEffect(() => {
        if (relocatedBatchStatus !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        setIsOpenRelocateModal(false);
        handleDetailBatchRequest();
        toastSuccess(t(`${I18N_PREFIX}.relocate-operation.toast.success`));
    }, [relocatedBatchStatus, closeToast, toastSuccess, t, handleDetailBatchRequest]);

    useEffect(() => {
        if (archiveOperationStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [archiveOperationStatus, toastLoading]);

    useEffect(() => {
        if (archiveOperationStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
        setModalAction(undefined);
        dispatch(archiveBatchOperationResetState());
    }, [archiveOperationStatus, closeToast, dispatch]);

    useEffect(() => {
        if (archiveOperationStatus !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        setModalAction(undefined);
        dispatch(archiveBatchOperationResetState());
        handleDetailBatchRequest();
        toastSuccess(t(`${I18N_PREFIX}.archive-operation.toast.success`));
    }, [archiveOperationStatus, closeToast, dispatch, handleDetailBatchRequest, toastSuccess, t]);

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    const handleSort = (property: BatchOperationSortableProperties, order: SortOrder) => {
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty(undefined);
            setSortOrder(undefined);
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const hasError = batchStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.batch.error-message', { count: 1 })}</div>
                <BackButton label={t('global.try-again')} onClick={handleDetailBatchRequest} />
            </div>
        );
    }

    const isLoadingBatch = batchStatus !== HttpRequestStatus.SUCCESS;
    const isLoadingBatchOperations = batchOperationsStatus !== HttpRequestStatus.SUCCESS;
    const disabledModalButton = exportBatchStatus === HttpRequestStatus.ON_GOING;
    const isBackdropActive =
        deletedBatchOperationStatus === HttpRequestStatus.ON_GOING || archiveOperationStatus === HttpRequestStatus.ON_GOING;

    return (
        <>
            {isBackdropActive && <BackDrop open />}
            <main className="scd-batch-detail-operations-legacy">
                <ContextRibbon />
                <section className="scd-batch-detail-operations-legacy__container">
                    {isLoadingBatch ? (
                        <Loading />
                    ) : (
                        <>
                            <header className="scd-batch-detail-operations-legacy__header">
                                <div className="scd-batch-detail-operations-legacy__header-container">
                                    <h2 className="scd-batch-detail-operations-legacy__header-container--title">
                                        {`${t(`${I18N_PREFIX}.product.options.${batch?.product}`).toUpperCase()} ${batch?.code}`}
                                        <RefreshButton onClick={handleTryAgain} />
                                    </h2>
                                    <div className="scd-batch-detail-operations-legacy__header-container--subtitle">
                                        <span style={{ marginRight: '10px' }}>
                                            {batch?.originator ?? t(`${I18N_PREFIX}.no-originator`)}
                                        </span>
                                        <DateFormatter date={batch?.date} />
                                    </div>
                                </div>
                            </header>
                            <div className="scd-batch-detail-operations-legacy__table">
                                <div className="scd-batch-detail-operations-legacy__table__header">
                                    <h3>{t(`${I18N_PREFIX}.detail.operations`)}</h3>
                                    <span className="scd-batch-detail-operations-legacy__table__header-operations">
                                        {batch?.numberOfOperations ?? '-'}
                                    </span>
                                    <div className="scd-batch-detail-operations-legacy__table__header-align-right">
                                        {(batch?.status === BatchStatus.CLOSED || batch?.status === BatchStatus.CONCLUDED) && (
                                            <span className="batch-report-modal--link" onClick={() => setIsOpenReportModal(true)}>
                                                <div className="batch-report-modal--link__icon" />
                                                {t(`${I18N_PREFIX}.detail.report.link`).toUpperCase()}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {batch ? (
                                    <>
                                        <table className="page-container--table">
                                            <thead>
                                                <ScdBatchOperationsHeader
                                                    operations={batchOperations?.content ?? []}
                                                    handleSort={handleSort}
                                                    sortedProperty={sortedProperty}
                                                    sortOrder={sortOrder}
                                                    disabledSort={false}
                                                />
                                            </thead>
                                            <tbody>
                                                {isLoadingBatchOperations ? (
                                                    <Loading isTable />
                                                ) : (
                                                    <BatchOperationsList
                                                        operations={batchOperations?.content ?? []}
                                                        batchStatus={batch.status}
                                                        setOperation={setOperation}
                                                        setModalAction={setModalAction}
                                                        paymentModePolicy={batch.paymentModePolicy}
                                                        paymentFavoredPolicy={batch.paymentFavoredPolicy}
                                                        setIsOpenRelocateModal={setIsOpenRelocateModal}
                                                        operation={operation}
                                                    />
                                                )}
                                            </tbody>
                                        </table>
                                        {!isLoadingBatchOperations && (
                                            <div style={{ marginBottom: '100px' }}>
                                                <Pagination
                                                    page={page}
                                                    totalPages={batchOperations?.totalPages ?? 0}
                                                    onChange={handlePageChange}
                                                />
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="scd-batch-detail-operations-legacy__error-message">
                                        <span>{t(`${I18N_PREFIX}.detail.empty-operations`)}</span>
                                    </div>
                                )}
                            </div>
                            <div className="scd-batch-detail-operations-legacy__content-slide">
                                <div className="scd-batch-detail-operations-legacy__content-slide--container">
                                    <div className="scd-batch-detail-operations-legacy__content-slide--item">
                                        <div className="scd-batch-detail-operations-legacy__content-slide--item__title">
                                            {t(`${I18N_PREFIX}.detail.total`).toUpperCase()}
                                        </div>
                                        <div className="scd-batch-detail-operations-legacy__content-slide--item__value">
                                            <strong>
                                                {batch?.nominalValue ? (
                                                    <>
                                                        <CurrencyFormatter value={batch?.nominalValue} prefix />
                                                    </>
                                                ) : (
                                                    '-'
                                                )}
                                            </strong>
                                        </div>
                                    </div>
                                    {(batch?.status === BatchStatus.CLOSED || batch?.status === BatchStatus.CONCLUDED) && (
                                        <div className="scd-batch-detail-operations-legacy__content-slide--item">
                                            <div className="scd-batch-detail-operations-legacy__content-slide--item__title">
                                                {t(`${I18N_PREFIX}.detail.payment`).toUpperCase()}
                                            </div>
                                            <div className="scd-batch-detail-operations-legacy__content-slide--item__value">
                                                <strong>{batch?.numberOfPayments ?? '-'}</strong>
                                            </div>
                                        </div>
                                    )}
                                    <div className="scd-payment-transaction-in-series__content-slide--buttons">
                                        {batch?.status &&
                                            (
                                                {
                                                    OPEN: (
                                                        <span style={{ width: '145px' }}>
                                                            <LinkButton
                                                                label={t(`${I18N_PREFIX}.detail.button.close-batch`)}
                                                                onClick={() => setModalCloseDetails(true)}
                                                            />
                                                        </span>
                                                    ),
                                                    CLOSED: (
                                                        <>
                                                            {!batch?.hasCnab && (
                                                                <span
                                                                    className="scd-batch-detail-operations-legacy__content--link"
                                                                    onClick={handleOpenGenerateCnabModal}
                                                                >
                                                                    {t(`${I18N_PREFIX}.detail.modal.generate-cnab.title`).toUpperCase()}
                                                                </span>
                                                            )}
                                                            {batch?.hasCnab && (
                                                                <span
                                                                    className="scd-batch-detail-operations-legacy__content--link"
                                                                    onClick={() =>
                                                                        setModalAction(BatchModalActionOptions.SEND_CNAB_DOCUMENT)
                                                                    }
                                                                >
                                                                    {t(
                                                                        `${I18N_PREFIX}.detail.modal.send-cnab-document.title`
                                                                    ).toUpperCase()}
                                                                </span>
                                                            )}
                                                            <span
                                                                className="scd-batch-detail-operations-legacy__content--link"
                                                                onClick={() => setModalAction(BatchModalActionOptions.SEND_CCB_DOCUMENTS)}
                                                            >
                                                                {t(`${I18N_PREFIX}.detail.modal.send-ccb-documents.title`).toUpperCase()}
                                                            </span>
                                                            <span style={{ width: '145px', marginRight: '30px' }}>
                                                                <LinkButton
                                                                    label={t(`${I18N_PREFIX}.detail.button.export-webcred`)}
                                                                    onClick={() => setModalAction(BatchModalActionOptions.EXPORT_WEBCRED)}
                                                                    startIcon={<SendIcon />}
                                                                    outlinedStyle
                                                                />
                                                            </span>
                                                            <span style={{ width: '145px' }}>
                                                                <LinkButton
                                                                    label={t(`${I18N_PREFIX}.detail.button.transfer`)}
                                                                    onClick={() =>
                                                                        setModalAction(BatchModalActionOptions.TRANSFER_OPERATION)
                                                                    }
                                                                    startIcon={<MonetizationOnIcon />}
                                                                />
                                                            </span>
                                                        </>
                                                    ),
                                                    CONCLUDED: (
                                                        <>
                                                            {!batch?.hasCnab && (
                                                                <span
                                                                    className="scd-batch-detail-operations-legacy__content--link"
                                                                    onClick={handleOpenGenerateCnabModal}
                                                                >
                                                                    {t(`${I18N_PREFIX}.detail.modal.generate-cnab.title`).toUpperCase()}
                                                                </span>
                                                            )}
                                                            {batch?.hasCnab && (
                                                                <span
                                                                    className="scd-batch-detail-operations-legacy__content--link"
                                                                    onClick={() =>
                                                                        setModalAction(BatchModalActionOptions.SEND_CNAB_DOCUMENT)
                                                                    }
                                                                >
                                                                    {t(
                                                                        `${I18N_PREFIX}.detail.modal.send-cnab-document.title`
                                                                    ).toUpperCase()}
                                                                </span>
                                                            )}
                                                            <span
                                                                className="scd-batch-detail-operations-legacy__content--link"
                                                                onClick={() => setModalAction(BatchModalActionOptions.SEND_CCB_DOCUMENTS)}
                                                            >
                                                                {t(`${I18N_PREFIX}.detail.modal.send-ccb-documents.title`).toUpperCase()}
                                                            </span>
                                                            <span style={{ width: '145px' }}>
                                                                <LinkButton
                                                                    label={t(`${I18N_PREFIX}.detail.button.export-webcred`)}
                                                                    onClick={() => setModalAction(BatchModalActionOptions.EXPORT_WEBCRED)}
                                                                    startIcon={<SendIcon />}
                                                                    outlinedStyle
                                                                />
                                                            </span>
                                                        </>
                                                    ),
                                                } as Record<BatchStatus, React.ReactNode>
                                            )[batch?.status]}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <BatchModalCloseDetails
                        batchId={batchId}
                        setModalCloseDetails={setModalCloseDetails}
                        modalCloseDetails={modalCloseDetails}
                        batchCode={batch?.code}
                    />
                    <BatchModalAction
                        batchId={batchId}
                        modalAction={modalAction}
                        setModalAction={setModalAction}
                        operation={operation}
                        disabled={disabledModalButton}
                    />
                    <BatchReportModal batchId={batchId} isOpenModal={isOpenReportModal} setIsOpenModal={setIsOpenReportModal} />
                    <BatchGenerateCnabModal
                        batchId={batchId}
                        cnabTemplate={batch?.cnabTemplate}
                        isOpenModal={isOpenGenerateCnabModal}
                        setIsOpenModal={setIsOpenGenerateCnabModal}
                        onSuccess={handleDetailBatchRequest}
                    />
                    <RelocateOperationBatchModal
                        isOpenModal={isOpenRelocateModal}
                        batchId={batchId}
                        operation={operation}
                        setIsOpenRelocateModal={setIsOpenRelocateModal}
                    />
                    {(createdBatchToRelocate || relocatedBatch) && (
                        <BatchRelocateSuccessModal createdBatchToRelocate={createdBatchToRelocate} relocatedBatch={relocatedBatch} />
                    )}
                </section>
            </main>
        </>
    );
};

export default BatchDetailOperationsLegacy;
