import clsx from 'clsx';
import ptBR from 'date-fns/locale/pt-BR';
import { DateRange } from 'model/imported-operation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './date-range-picker.scss';

export interface SimpleDatePickerInputProps {
    dateRange: DateRange;
    setDateRange: (dateRange: DateRange) => void;
}

export const DateRangePicker = (props: SimpleDatePickerInputProps) => {
    const { dateRange, setDateRange } = props;

    const hasStartDate: boolean = !!dateRange?.start;
    const hasEndDate: boolean = !!dateRange?.end;

    return (
        <div className="date-picker">
            <div className="date-picker__input-box">
                <div className="date-picker--calendar-icon" />
                <div className={clsx('date-picker__input-box--label', { HAS_DATE: hasStartDate })}>Periodo inicial</div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={dateRange?.start}
                    onChange={date => setDateRange?.({ end: dateRange?.start ?? null, start: date })}
                    selectsStart
                    calendarIconClassname="date__input--calendar"
                    startDate={dateRange?.start}
                    endDate={dateRange?.end}
                    locale={ptBR}
                />
            </div>
            -
            <div className="date-picker__input-box">
                <div className="date-picker--calendar-icon" />
                <div className={clsx('date-picker__input-box--label', { HAS_DATE: hasEndDate })}>Periodo final</div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={dateRange?.end}
                    selectsEnd
                    startDate={dateRange?.start}
                    endDate={dateRange?.end}
                    onChange={date => setDateRange?.({ start: dateRange?.start ?? null, end: date })}
                    locale={ptBR}
                />
            </div>
        </div>
    );
};

export default DateRangePicker;
