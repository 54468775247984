import { Grid } from '@material-ui/core';
import { ClearButton } from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleDatePickerInput from 'components/inputs/date-input/date-picker/simple-date-picker-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import SimpleTextNumberInput from 'components/inputs/text-number-input/simple-text-number-input';
import { ScdRequestLogFilterRequest } from 'model/request-log';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { valuesOfObject } from 'shared/util/object-utils';
import './request-log-modal-filter.scss';

export type RequestLogModalFilterType = 'filters' | 'createdDate' | 'responseStatus' | 'organizationKey' | 'route';

export interface RequestLogModalFilterProps {
    onClose: () => void;
    modalFilter: RequestLogModalFilterType;
    openModal: boolean;
    title: string;
    filterActive: ScdRequestLogFilterRequest;
    setFilterActive: (value: ScdRequestLogFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.request-log.filter.inputs';

export const DISABLED_MODAL_BUTTON_FUNCTION_RECORD = {
    createdDate: filter => !filter.createdDate,
    responseStatus: filter => !filter.responseStatus,
    organizationKey: filter => !filter.organizationKey,
    route: filter => !filter.route,
    filters: filter => valuesOfObject({ ...filter }).length === 0,
} as Record<RequestLogModalFilterType, (filter: ScdRequestLogFilterRequest) => boolean>;

export const RequestLogModalFilter = (props: RequestLogModalFilterProps) => {
    const { onClose, modalFilter, openModal, title, setFilterActive, filterActive, setPage } = props;

    const [filterRequest, setFilterRequest] = useState<ScdRequestLogFilterRequest>(filterActive);

    const { t } = useTranslation();

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    if (!openModal) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<ScdRequestLogFilterRequest>) => {
        setFilterRequest({ ...filterRequest, ...value });
    };

    const handleClearFilter = {
        createdDate: () => {
            setFilterRequest({ ...filterRequest, createdDate: undefined });
            setFilterActive({ ...filterActive, createdDate: undefined });
        },
        responseStatus: () => {
            setFilterRequest({ ...filterRequest, responseStatus: undefined });
            setFilterActive({ ...filterActive, responseStatus: undefined });
        },
        organizationKey: () => {
            setFilterRequest({ ...filterRequest, organizationKey: undefined });
            setFilterActive({ ...filterActive, organizationKey: undefined });
        },
        route: () => {
            setFilterRequest({ ...filterRequest, route: undefined });
            setFilterActive({ ...filterActive, route: undefined });
        },
        filters: () => {
            setFilterRequest({});
            setFilterActive({});
        },
    } as Record<RequestLogModalFilterType, () => void>;

    const CreatedDateFilter: JSX.Element = (
        <>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.createdDate.initialDate`)}
                    placeholder={t(`${I18N_PREFIX}.createdDate.initialDate`)}
                    value={filterRequest.createdDate?.start!}
                    onChange={start => handleChange({ createdDate: { ...filterRequest.createdDate, start } })}
                    endAdornment
                    externalUpdate
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.createdDate.finalDate`)}
                    placeholder={t(`${I18N_PREFIX}.createdDate.finalDate`)}
                    value={filterRequest.createdDate?.end!}
                    onChange={end => handleChange({ createdDate: { ...filterRequest.createdDate, end } })}
                    endAdornment
                    externalUpdate
                />
            </Grid>
        </>
    );

    const ResponseStatusFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextNumberInput
                    label={t(`${I18N_PREFIX}.responseStatus`)}
                    placeholder={t(`${I18N_PREFIX}.responseStatus`)}
                    onChange={responseStatus => handleChange({ responseStatus: Number(responseStatus) })}
                    value={filterRequest?.responseStatus?.toString()}
                    externalUpdate
                />
            </Grid>
        </>
    );
    const OrganizationKeyFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextInput
                    label={t(`${I18N_PREFIX}.organizationKey`)}
                    placeholder={t(`${I18N_PREFIX}.organizationKey`)}
                    onChange={organizationKey => handleChange({ organizationKey: organizationKey })}
                    value={filterRequest?.organizationKey}
                    externalUpdate
                />
            </Grid>
        </>
    );
    const RouteFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextInput
                    label={t(`${I18N_PREFIX}.route`)}
                    placeholder={t(`${I18N_PREFIX}.route`)}
                    onChange={route => handleChange({ route: route })}
                    value={filterRequest?.route}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        openModal && (
            <Modal isOpen>
                <div className="request-log-modal-filter">
                    <div className="request-log-modal-filter--header">
                        <label className="request-log-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {CreatedDateFilter}
                                            {ResponseStatusFilter}
                                            {OrganizationKeyFilter}
                                            {RouteFilter}
                                        </>
                                    ),
                                    createdDate: CreatedDateFilter,
                                    responseStatus: ResponseStatusFilter,
                                    organizationKey: OrganizationKeyFilter,
                                    route: RouteFilter,
                                } as Record<RequestLogModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>

                    <div className="request-log-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default RequestLogModalFilter;
