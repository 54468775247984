import { AxiosResponse } from 'axios';
import {
    ScdBatchClose,
    ScdBatchCommissionReport,
    ScdBatchCreatedByOperations,
    ScdBatchData,
    ScdBatchDeleteOperation,
    ScdBatchDetail,
    ScdBatchDisbursementReport,
    ScdBatchExpirationsReport,
    ScdBatchExport,
    ScdBatchOperationsReport,
    ScdBatchSimple,
    ScdBatchToCreateByOperations,
    ScdBatchToRelocate,
    ScdBatchToRelocateResponse,
    ScdBatchToRelocateCreateNewRequest,
    ScdSearchPaymentImportBatch,
    ScdBatchToRelocateToExistingRequest,
    ScdBatchToArchiveRequest,
    ScdBatchToArchiveResponse,
    ScdBatchToGenerateCessionRequest,
    ScdBatchToGenerateCessionResponse,
    BatchCloseDetails,
    BatchDetailWithProgram,
    BatchOperations
} from 'model/batch';
import { BatchFilterPageable, BatchSearchPageable, BatchSearchToRelocate, SearchRequest } from 'model/reducers';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';
import {TIMEOUT_FIVE_MINUTES_MS} from "config/constants";

const batch = () => {
    const listBatches = (request: BatchFilterPageable): Promise<AxiosResponse<Page<ScdBatchData>>> => {
        const { pageable, filter } = request;

        const _requestFilters = [
            filter?.originator ? `originatorId=${filter?.originator.id}` : undefined,
            filter?.product ? `product=${filter.product}` : undefined,
            filter?.status ? `status=${filter.status}` : undefined,
            `todayOnly=${filter?.todayOnly}`,
        ]
            .filter(it => !!it)
            .join('&');

        return api.get<Page<ScdBatchData>>(`api/batches?${_requestFilters}`, { params: { ...pageable } });
    };

    const searchPaymentImportBatches = (request: SearchRequest): Promise<AxiosResponse<Page<ScdSearchPaymentImportBatch>>> => {
        return api.get<Page<ScdSearchPaymentImportBatch>>(
            `api/batches/payment-import/search?search=${encodeURIComponent(request.search)}&sort=createdDate,desc`,
            {
                params: { ...request.pageable },
            }
        );
    };

    const detailBatch = (batchId: number): Promise<AxiosResponse<ScdBatchDetail>> => {
        return api.get<ScdBatchDetail>(`api/batches/${batchId}/resume`);
    };

    const getOperationsReport = (batchId: number): Promise<AxiosResponse<ScdBatchOperationsReport>> => {
        return api.get<ScdBatchOperationsReport>(`api/batches/${batchId}/report/operations`);
    };

    const getExpirationsReport = (batchId: number): Promise<AxiosResponse<ScdBatchExpirationsReport>> => {
        return api.get<ScdBatchExpirationsReport>(`api/batches/${batchId}/report/expirations`);
    };

    const getDisbursementReport = (batchId: number): Promise<AxiosResponse<ScdBatchDisbursementReport>> => {
        return api.get<ScdBatchDisbursementReport>(`api/batches/${batchId}/report/disbursements`);
    };

    const getCommissionReport = (batchId: number): Promise<AxiosResponse<ScdBatchCommissionReport>> => {
        return api.get<ScdBatchCommissionReport>(`api/batches/${batchId}/report/commissions`);
    };

    const closeBatch = (batchId: number): Promise<AxiosResponse<ScdBatchClose>> => {
        return api.patch<ScdBatchClose>(`api/batches/${batchId}/close`);
    };

    const deleteBatchOperation = (request: ScdBatchDeleteOperation): Promise<AxiosResponse<ScdBatchSimple>> => {
        return api.delete<ScdBatchSimple>(`${BASE_URL_ADMIN}/batches/${request.batchId}/operations/${request.operationId}`);
    };

    const reprocessBatch = (batchId: number): Promise<AxiosResponse<ScdBatchClose>> => {
        return api.patch<ScdBatchClose>(`api/batches/${batchId}/reprocess`);
    };

    const exportBatch = (batchId: number): Promise<AxiosResponse<ScdBatchExport>> => {
        return api.patch<ScdBatchExport>(`api/batches/${batchId}/export`, {}, { timeout: 0 });
    };

    const createBatchByOperations = (request: ScdBatchToCreateByOperations): Promise<AxiosResponse<ScdBatchCreatedByOperations>> => {
        return api.post<ScdBatchCreatedByOperations>(`api/batches/by-operations`, request);
    };

    const searchBatches = (request: BatchSearchPageable): Promise<AxiosResponse<Page<ScdBatchData>>> => {
        const { pageable, search } = request;

        return api.get<Page<ScdBatchData>>(`api/batches/search?search=${search}`, { params: { ...pageable } });
    };

    const sendBatchCcbDocuments = (batchId: number): Promise<AxiosResponse<ScdBatchSimple>> => {
        return api.post<ScdBatchSimple>(`api/batches/${batchId}/send-original-documents`)
    };

    const sendBatchCnabDocument = (batchId: number): Promise<AxiosResponse<ScdBatchSimple>> => {
        return api.post<ScdBatchSimple>(`api/batches/${batchId}/send-cnab-document`)
    };

    const batchSearchToRelocate = (request: BatchSearchToRelocate): Promise<AxiosResponse<Page<ScdBatchToRelocate>>> => {
        return api.get<Page<ScdBatchToRelocate>>(`${BASE_URL_ADMIN}/batches/${request.batchId}/relocate-to/batches/search?search=${request.search}`, {
            params: { ...request.pageable },
        })
    }

    const batchToRelocateCreateNew = (request: ScdBatchToRelocateCreateNewRequest): Promise<AxiosResponse<ScdBatchToRelocateResponse>> => {
        return api.post<ScdBatchToRelocateResponse>(`${BASE_URL_ADMIN}/batches/${request.id}/imported-operations/${request.operationId}/relocate-to/batches`)
    }

    const batchToRelocateToExisting = (request: ScdBatchToRelocateToExistingRequest): Promise<AxiosResponse<ScdBatchToRelocateResponse>> => {
        return api.post<ScdBatchToRelocateResponse>(`${BASE_URL_ADMIN}/batches/${request.id}/imported-operations/${request.operationId}/relocate-to/batches/${request.batchTargetId}`)
    }

    const archiveBatchOperation = (request: ScdBatchToArchiveRequest): Promise<AxiosResponse<ScdBatchToArchiveResponse>> => {
        return api.post(`${BASE_URL_ADMIN}/batches/${request.id}/imported-operations/${request.operationId}/archive`)
    }

    const generateCession = (request: ScdBatchToGenerateCessionRequest): Promise<AxiosResponse<ScdBatchToGenerateCessionResponse>> => {
        return api.post(`${BASE_URL_ADMIN}/batches/${request.batchId}/cession`, request,{ timeout: TIMEOUT_FIVE_MINUTES_MS })
    }

    const batchCloseDetails = (batchId: number): Promise<AxiosResponse<BatchCloseDetails>> => {
        return api.get(`${BASE_URL_ADMIN}/batches/${batchId}/close/details`)
    }

    const detailBatchWithProgram = (batchId: number): Promise<AxiosResponse<BatchDetailWithProgram>> => {
        return api.get<BatchDetailWithProgram>(`${BASE_URL_ADMIN}/batches/${batchId}`)
    }

    const batchOperationsSummarize = (batchId: number): Promise<AxiosResponse<BatchOperations>> => {
        return api.get<BatchOperations>(`${BASE_URL_ADMIN}/batches/${batchId}/operations/summarize`)
    }

    const batchConclude = (batchId: number): Promise<AxiosResponse<ScdBatchSimple>> => {
        return api.post<ScdBatchSimple>(`${BASE_URL_ADMIN}/batches/${batchId}/conclude`)
    }

    return {
        listBatches,
        detailBatch,
        searchPaymentImportBatches,
        getOperationsReport,
        getExpirationsReport,
        getDisbursementReport,
        getCommissionReport,
        closeBatch,
        deleteBatchOperation,
        reprocessBatch,
        exportBatch,
        createBatchByOperations,
        searchBatches,
        sendBatchCcbDocuments,
        sendBatchCnabDocument,
        batchSearchToRelocate,
        batchToRelocateCreateNew,
        batchToRelocateToExisting,
        archiveBatchOperation,
        generateCession,
        batchCloseDetails,
        detailBatchWithProgram,
        batchOperationsSummarize,
        batchConclude
    };
};

export default batch();
