import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { RequestLogModelBrs } from 'model/brs/request-log-brs';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.request-log.table';

interface RequestLogHeaderBrsProps {
    requestLogs: RequestLogModelBrs[];
    handleSort?: (property: keyof RequestLogModelBrs, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort?: boolean;
}

export const RequestLogHeaderBrs = (props: RequestLogHeaderBrsProps) => {
    const { t } = useTranslation();
    const { requestLogs, handleSort, sortedProperty, sortOrder, disabledSort } = props;

    const mapSortable = (_property: keyof RequestLogModelBrs) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (requestLogs && requestLogs.length <= 1) ? true : false,
            onSort: () => handleSort && handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.createdDate`).toUpperCase()} sortable={mapSortable('createdDate')} />
            <SortableColumn label={t(`${I18N_PREFIX}.responseStatus`).toUpperCase()} sortable={mapSortable('status')} />
            <SortableColumn label={t(`${I18N_PREFIX}.agent`).toUpperCase()} sortable={mapSortable('agent')} />
            <SortableColumn label={t(`${I18N_PREFIX}.route`).toUpperCase()} sortable={mapSortable('route')} />
        </tr>
    );
};

export default RequestLogHeaderBrs;
