import { AxiosResponse } from 'axios';
import { RequestLogFilterPageableBrs, RequestLogModelBrs } from 'model/brs/request-log-brs';
import { api } from 'services/api/api';
import { BASE_URL_BRS, Page } from 'services/page';

export const requestLogs = () => {
    const listRequestLogs = (request: RequestLogFilterPageableBrs): Promise<AxiosResponse<Page<RequestLogModelBrs>>> => {
        const { pageable, filter } = request;

        const _requestFilters = [
            filter?.agent ? `agent=${filter?.agent}` : undefined,
            filter?.status ? `status=${filter.status}` : undefined,
            filter?.route ? `route=${filter.route}` : undefined,
            filter?.createdDate?.start && filter?.createdDate?.end,
        ]
            .filter(it => !!it)
            .join('&');

        return api.get<Page<RequestLogModelBrs>>(`${BASE_URL_BRS}/request-logs?${_requestFilters}`, { params: { ...pageable } });
    };

    return {
        listRequestLogs,
    };
};

export default requestLogs();
