import clsx from 'clsx';
import { DATE_FNS_LOCAL_DATE_FORMAT } from 'config/constants';
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import './simple-date-picker.scss';

const I18N_PREFIX = 'global.simple-data-picker';

export interface SimpleDatePickerInputProps {
    selectedDate: Date | null;
    setSelectedDate: (date: Date | null) => void;
    label?: string;
}

export const SimpleDatePicker = (props: SimpleDatePickerInputProps) => {
    const { t } = useTranslation();

    const { selectedDate, setSelectedDate, label } = props;

    const hasDate: boolean = Boolean(selectedDate);

    return (
        <div className="simple-date-picker">
            <div className="simple-date-picker__input-box">
                <div className="simple-date-picker--calendar-icon" />
                <label className={clsx('simple-date-picker__input-box--label', { HAS_DATE: hasDate })}>
                    {hasDate ? label : t(`${I18N_PREFIX}.message`)}
                </label>
                <DatePicker
                    dateFormat={DATE_FNS_LOCAL_DATE_FORMAT}
                    selected={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    calendarIconClassname="date__input--calendar"
                    locale={ptBR}
                />
            </div>
        </div>
    );
};

export default SimpleDatePicker;
