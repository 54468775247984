import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import { RequestLogsRowProps } from 'entities/request-log/request-log-list-brs/request-log-list-brs';
import { ListChildComponentProps } from 'react-window';

const RequestLogRow = (props: ListChildComponentProps<RequestLogsRowProps>) => {
    const { index, data, style } = props;

    const { logs, setRequestLogData } = data;

    const { id, createdDate, status, agent, route } = logs[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">
                {createdDate ? <DateFormatter date={createdDate} format={DateFormat.DATETIME_UTC} /> : '00/00/0000'}
            </td>
            <td className="page-container--table-cell-title">{status ?? '-'}</td>
            <td className="page-container--table-cell-title">{agent ?? '-'}</td>
            <td className="page-container--table-cell-title">{route ?? '-'}</td>
            <td className="page-container--table-cell-icon">
                <VisualizeIconButton onClick={() => setRequestLogData(logs[index])} />
            </td>
        </tr>
    );
};

export default RequestLogRow;
