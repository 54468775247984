import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import ClearButton from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleCurrencyInput from 'components/inputs/currency-input/simple-currency-input';
import SimpleDatePickerInput from 'components/inputs/date-input/date-picker/simple-date-picker-input';
import ListSearchInput from 'components/inputs/list-search-input/list-search-input';
import { SEARCH_ITEM_SIZE, SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import CompletePaymentTransactionsFavoredInputSeacrh from 'entities/payment-transaction/complete-payment-transaction/components/complete-payment-transactions-favored-input-search/complete-payment-transactions-favored-input-search';
import { PaymentTransactionMode } from 'model/enums/payment-transaction-mode';
import { ScdOriginatorSearch } from 'model/originator';
import { CompletePaymentTransactionFilterData, ScdPaymentTransactionPendingData } from 'model/payment-transaction';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { IRootState } from 'reducer';
import { searchOriginatorsRequest } from 'reducer/originator/search-originators/actions';
import { valuesOfObject } from 'shared/util/object-utils';
import './complete-payment-transactions-modal-filter.scss';

export type CompletePaymentTransactionFilterType = 'filters' | 'date' | 'amount' | 'favored' | 'originator' | 'mode';

interface CompletePaymentTransactionModalFilterProps {
    openModalFilter: boolean;
    modalFilter: CompletePaymentTransactionFilterType;
    title: string;
    onClose: () => void;
    setFilterActive: (value: CompletePaymentTransactionFilterData) => void;
    filterActive: CompletePaymentTransactionFilterData;
    paymentTransactions: ScdPaymentTransactionPendingData[];
}

const I18N_PREFIX = 'pages.scd.complete-payment-transactions.filter';

export const DISABLED_MODAL_BUTTON_FUNCTION_RECORD = {
    filters: filter => valuesOfObject({ ...filter }).length === 0,
    date: filter => !filter.date,
    amount: filter => !filter.amount,
    favored: filter => !filter.favored,
    originator: filter => !filter.originator,
    mode: filter => !filter.mode,
} as Record<CompletePaymentTransactionFilterType, (filter: CompletePaymentTransactionFilterData) => boolean>;

const CompletePaymentTransactionModalFilter = (props: CompletePaymentTransactionModalFilterProps) => {
    const { openModalFilter, modalFilter, title, onClose, filterActive, setFilterActive, paymentTransactions } = props;

    const { t } = useTranslation();

    const [filterRequest, setFilterRequest] = useState<CompletePaymentTransactionFilterData>(filterActive);

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    if (!openModalFilter) {
        return <></>;
    }

    const handleChange = (value: Partial<CompletePaymentTransactionFilterData>) => {
        setFilterRequest({ ...filterRequest, ...value });
    };

    const handleClearFilter = {
        filters: () => {
            setFilterRequest({});
            setFilterActive({});
        },
        date: () => {
            setFilterRequest({ ...filterRequest, date: undefined });
            setFilterActive({ ...filterRequest, date: undefined });
        },
        amount: () => {
            setFilterRequest({ ...filterRequest, amount: undefined });
            setFilterActive({ ...filterRequest, amount: undefined });
        },
        favored: () => {
            setFilterRequest({ ...filterRequest, favored: undefined });
            setFilterActive({ ...filterRequest, favored: undefined });
        },
        mode: () => {
            setFilterRequest({ ...filterRequest, mode: undefined });
            setFilterActive({ ...filterRequest, mode: undefined });
        },
        originator: () => {
            setFilterRequest({ ...filterRequest, originator: undefined });
            setFilterActive({ ...filterRequest, originator: undefined });
        },
    } as Record<CompletePaymentTransactionFilterType, () => void>;

    const handleConfirmRequest = () => {
        setFilterActive(filterRequest);
        onClose();
    };

    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);

    const DateFilter: JSX.Element = (
        <>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.input.initialDate`)}
                    placeholder={t(`${I18N_PREFIX}.input.initialDate`)}
                    value={filterRequest.date?.initialDate!}
                    onChange={initialData => handleChange({ date: { ...filterRequest.date, initialDate: initialData } })}
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.input.finalDate`)}
                    placeholder={t(`${I18N_PREFIX}.input.finalDate`)}
                    value={filterRequest.date?.finalDate!}
                    onChange={finalData => handleChange({ date: { ...filterRequest.date, finalDate: finalData } })}
                />
            </Grid>
        </>
    );

    const AmountFilter: JSX.Element = (
        <>
            <Grid item xs={6}>
                <SimpleCurrencyInput
                    label={t(`${I18N_PREFIX}.input.minValue`)}
                    placeholder={t(`${I18N_PREFIX}.input.minValue`)}
                    onChange={minValue => handleChange({ amount: { ...filterRequest.amount, minValue } })}
                    value={filterRequest?.amount?.minValue}
                    externalUpdate
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleCurrencyInput
                    label={t(`${I18N_PREFIX}.input.maxValue`)}
                    placeholder={t(`${I18N_PREFIX}.input.maxValue`)}
                    onChange={maxValue => handleChange({ amount: { ...filterRequest.amount, maxValue } })}
                    value={filterRequest?.amount?.maxValue}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const PaymentFavoredFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <CompletePaymentTransactionsFavoredInputSeacrh
                    label={t(`${I18N_PREFIX}.input.favored`)}
                    placeholder={t(`${I18N_PREFIX}.input.favored`)}
                    value={
                        filterRequest?.favored?.identification && filterRequest?.favored?.name
                            ? `${filterRequest.favored.identification} ${filterRequest.favored.name}`
                            : ''
                    }
                    itemSize={SEARCH_ITEM_SIZE}
                    renderItem={favored => `${favored?.favoredIdentification} ${favored?.favoredName}`}
                    paymentTransactions={paymentTransactions}
                    onChange={favored => {
                        handleChange({
                            favored: {
                                name: favored?.favoredName,
                                identification: favored?.favoredIdentification,
                            },
                        });
                    }}
                />
            </Grid>
        </>
    );

    const OriginatorFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <ListSearchInput<ScdOriginatorSearch>
                    action={searchOriginatorsRequest}
                    itemSize={SEARCH_ITEM_SIZE_LARGE}
                    onSelect={originator => handleChange({ originator })}
                    renderItem={originator => `${originator.id} ${originator?.name}`}
                    statusSelector={(state: IRootState) => state.searchOriginators.status}
                    dataSelector={(state: IRootState) => state.searchOriginators.originators}
                    label={t(`${I18N_PREFIX}.input.originator`)}
                    placeholder={t(`${I18N_PREFIX}.input.originator`)}
                    value={
                        filterRequest?.originator?.id && filterRequest?.originator?.name
                            ? `${filterRequest.originator.id} ${filterRequest.originator.name}`
                            : ''
                    }
                />
            </Grid>
        </>
    );

    const ModeFilter: JSX.Element = (
        <>
            <div
                className={clsx('complete-payment-transaction-modal-filter--container-mode--label', {
                    MANUAL: filterRequest.mode === PaymentTransactionMode.MANUAL,
                })}
                onClick={() => handleChange({ mode: PaymentTransactionMode.MANUAL })}
            >
                {t(`${I18N_PREFIX}.mode.options.MANUAL`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('complete-payment-transaction-modal-filter--container-mode--label', {
                    AUTOMATIC: filterRequest.mode === PaymentTransactionMode.AUTOMATIC,
                })}
                onClick={() => handleChange({ mode: PaymentTransactionMode.AUTOMATIC })}
            >
                {t(`${I18N_PREFIX}.mode.options.AUTOMATIC`).toLocaleUpperCase()}
            </div>
        </>
    );

    return (
        openModalFilter && (
            <Modal isOpen>
                <div className="complete-payment-transaction-modal-filter--container">
                    <div className="complete-payment-transaction-modal-filter--header">
                        <label className="complete-payment-transaction-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {DateFilter}
                                            {AmountFilter}
                                            {PaymentFavoredFilter}
                                            {OriginatorFilter}
                                            {ModeFilter}
                                        </>
                                    ),
                                    date: DateFilter,
                                    amount: AmountFilter,
                                    favored: PaymentFavoredFilter,
                                    originator: OriginatorFilter,
                                    mode: ModeFilter,
                                } as Record<CompletePaymentTransactionFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>
                    <div className="complete-payment-transaction-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default CompletePaymentTransactionModalFilter;
