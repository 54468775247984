import { ScdProgramPolicy } from 'model/program-policy';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.program-policy.validate';

const PROGRAM_POLICY_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.scdProgram.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.scdProgram.required`,
    },
};

export const validateProgramPolicyName = (value: string | undefined | null) =>
    ValidationUtils.validate(value, PROGRAM_POLICY_NAME_VALIDATION);

const PROGRAM_POLICY_CNAB_TEMPLATE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.cnabTemplate.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.cnabTemplate.required`,
    },
};

export const validateProgramPolicyCnabTemplate = (value: string | undefined | null) =>
    ValidationUtils.validate(value, PROGRAM_POLICY_CNAB_TEMPLATE_VALIDATION);

const PROGRAM_PAYMENT_FAVORED_POLICY_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.paymentFavored.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.paymentFavored.required`,
    },
};

export const validateProgramPaymentFavoredPolicy = (value: string | undefined | null) =>
    ValidationUtils.validate(value, PROGRAM_PAYMENT_FAVORED_POLICY_VALIDATION);

const PROGRAM_POLICY_PAYMENT_MODE_POLICY_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.paymentModePolicy.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.paymentModePolicy.required`,
    },
};

export const validateProgramPolicyPaymentModePolicy = (value: string | undefined | null) =>
    ValidationUtils.validate(value, PROGRAM_POLICY_PAYMENT_MODE_POLICY_VALIDATION);

const PROGRAM_POLICY_OPERATION_APPROVAL_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.operationApprovalPolicy.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.operationApprovalPolicy.required`,
    },
};

export const validateProgramPolicyOperationApprovalPolicy = (value: string | undefined | null) =>
    ValidationUtils.validate(value, PROGRAM_POLICY_OPERATION_APPROVAL_VALIDATION);

const PROGRAM_POLICY_TRANSFER_DISPATCH_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.transferDispatchPolicy.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.transferDispatchPolicy.required`,
    },
};

const PROGRAM_DISBURSEMENT_AMENDMENT_POLICY_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.disbursementAmendmentPolicy.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.disbursementAmendmentPolicy.required`,
    },
};

export const validateProgramPolicyTransferDispatch = (value: string | undefined | null) => ValidationUtils.validate(value, PROGRAM_POLICY_TRANSFER_DISPATCH_VALIDATION)

export const validateProgramDisbursementAmendment = (value: string | undefined | null) => ValidationUtils.validate(value, PROGRAM_DISBURSEMENT_AMENDMENT_POLICY_VALIDATION)

export const validateProgramPolicy = (value: ScdProgramPolicy | undefined): boolean => {
    if (!value) return true;
    return (
        validateProgramPolicyName(value.scdProgram?.name).isValid &&
        validateProgramPolicyCnabTemplate(value.cnabTemplate).isValid &&
        validateProgramPaymentFavoredPolicy(value.paymentFavoredPolicy).isValid &&
        validateProgramPolicyPaymentModePolicy(value.paymentModePolicy).isValid &&
        validateProgramPolicyOperationApprovalPolicy(value.operationApprovalPolicy).isValid &&
        validateProgramPolicyTransferDispatch(value.transferDispatchModePolicy).isValid &&
        validateProgramDisbursementAmendment(value.disbursementAmendmentPolicy).isValid
    );
};
