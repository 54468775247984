import { FC, useEffect } from 'react';

import { useFooterComponents } from 'entities/batch/batch-outputs-detail/components/webcred/components/cession-integration-webcred-footer/hooks/use-footer-components';
import './cession-integration-webcred-footer.scss';
import SendCessionOperationModal from 'entities/batch/batch-outputs-detail/components/webcred/components/cession-integration-webcred-footer/send-cession-operation-modal/send-cession-operation-modal';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useRootDispatch } from 'reducer/hooks';
import { useTranslation } from 'react-i18next';
import { useProcessCessionOperationBco } from 'reducer-bco/hooks-bco';
import { useToast } from 'shared/hooks/use-toast';
import { processCessionOperationBcoResetState } from 'reducer-bco/batch-output/process-cession-operation/action';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-webcred.table.footer';

interface StandardFooterProps {
    handleRefresh: () => void;
}

const CessionIntegrationWebcredFooter: FC<StandardFooterProps> = ({ handleRefresh }) => {
    const { currentFooter, sendModalOpen, setIsSendModalOpen } = useFooterComponents();

    const dispatch = useRootDispatch();
    const { t } = useTranslation();

    const { status: processCessionOperationStatus, processCessionOperation } = useProcessCessionOperationBco();
    const { toastLoading, closeToast, toastSuccess, toastError } = useToast();

    useEffect(() => {
        if (processCessionOperationStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (processCessionOperationStatus === HttpRequestStatus.ERROR) {
            closeToast();
            dispatch(processCessionOperationBcoResetState());
            toastError(t(`${I18N_PREFIX}.send-cession-operation.toast.error`));
        }
        if (processCessionOperationStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            dispatch(processCessionOperationBcoResetState());
            handleRefresh();
            toastSuccess(t(`${I18N_PREFIX}.send-cession-operation.toast.success`));
        }
    }, [processCessionOperationStatus, toastLoading, closeToast, processCessionOperation, toastSuccess, t, dispatch]);

    // TODO: FAZER LOGICA DE TOAST E CHAMADA DE LISTAGEM APOS FINALIZAÇÃO

    return (
        <>
            {currentFooter}
            {sendModalOpen && <SendCessionOperationModal setIsModalOpen={setIsSendModalOpen} />}
        </>
    );
};

export default CessionIntegrationWebcredFooter;
