import ModalMessage from 'components/modals/modal-message/modal-message';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FC, useEffect } from 'react';
import {useCessionToResumeBco, useProcessCessionOperationBco,} from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import 'entities/batch/batch-outputs-detail/components/webcred/components/cession-integration-webcred-footer/send-cession-operation-modal/send-cession-operation-modal.scss';
import { CessionOperationRequestRequired } from 'model/bco/batch-output-cession-integration-webcred';
import {
    processCessionOperationBcoRequest,
    processCessionOperationBcoResetState,
} from 'reducer-bco/batch-output/process-cession-operation/action';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-webcred.table.footer.modal.process';

interface SendStreamModalProps {
    setIsModalOpen: (value: boolean) => void;
}

const SendCessionOperationModal: FC<SendStreamModalProps> = ({ setIsModalOpen }) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();

    const { status } = useProcessCessionOperationBco();
    const { cessionToResume } = useCessionToResumeBco();

    const cession = cessionToResume;

    const handleSendProcess = () => {
        if (!cession) return;
        const request: CessionOperationRequestRequired = {
            batchId: batchId,
            outputId: outputId,
        };
        dispatch(processCessionOperationBcoRequest(request));
        setIsModalOpen(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        return () => {
            dispatch(processCessionOperationBcoResetState());
        };
    }, [dispatch]);

    return (
        <ModalMessage
            title={t(`${I18N_PREFIX}.title`)}
            onClose={handleCloseModal}
            onAction={handleSendProcess}
            onCancel={handleCloseModal}
            standardButtonLabel={`${I18N_PREFIX}.button.send`}
            outlinedButtonLabel={'entity.action.back'}
            disabled={status === HttpRequestStatus.ON_GOING}
        >
            <div className="send-cession-operation-modal__text">{t(`${I18N_PREFIX}.message`, { count: cession?.numberOfOperations })}</div>
        </ModalMessage>
    );
};

export default SendCessionOperationModal;
