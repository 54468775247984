import CloseButton from 'components/buttons/close-button/close-button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { JsonUtils } from 'shared/external/util/json-util';
import './request-log-details-modal.scss';
import { RequestLogModelBrs } from 'model/brs/request-log-brs';

interface RequestLogDetailsModalProps {
    requestLogJson: RequestLogModelBrs;
    onClose: () => void;
}

const I18N_PREFIX = 'pages.scd.request-log';

const RequestLogDetailsModal = (props: RequestLogDetailsModalProps) => {
    const { requestLogJson, onClose } = props;

    const { t } = useTranslation();

    const prettifiedResponse = useMemo((): string => {
        return JsonUtils.prettifyJsonByObject(requestLogJson);
    }, [requestLogJson]);

    return (
        <Modal isOpen toggle={onClose}>
            <div className="request-log-details">
                <div className="request-log-details--header">
                    <label className="request-log-details--header-title"> {t(`${I18N_PREFIX}.modal-detail.title`)} </label>
                    <CloseButton onClick={onClose} />
                </div>
                <div className="request-log-details--container">
                    <pre>{prettifiedResponse}</pre>
                </div>
            </div>
        </Modal>
    );
};

export default RequestLogDetailsModal;
