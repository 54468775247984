import { ReactNode, useEffect, useState } from 'react';
import StandardButton from 'components/buttons/standard-button/standard-button';
import { useTranslation } from 'react-i18next';
import { useCessionToResumeBco } from 'reducer-bco/hooks-bco';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-webcred.table.footer.components';

enum WebcredIntegrationSteps {
    UNDEFINED = 'UNDEFINED',
    SEND_CESSION_OPERATION = 'SEND_CESSION_OPERATION',
    SEND_CESSION_OPERATION_PROCESSING = 'SEND_CESSION_OPERATION_PROCESSING',
    SEND_CESSION_OPERATION_FAILURE = 'SEND_CESSION_OPERATION_FAILURE',
    FINALIZE_CESSION = 'FINALIZE_CESSION',
    FINALIZE_CESSION_FAILURE = 'FINALIZE_CESSION_FAILURE',
    CESSION_SUCCESSFUL = 'CESSION_SUCCESSFUL',
}

export const useFooterComponents = () => {
    const { t } = useTranslation();

    const [isSendModalOpen, setIsSendModalOpen] = useState<boolean>(false);
    const [isFinalizeModalOpen, setIsFinalizeModalOpen] = useState<boolean>(false);
    const [footerType, setFooterType] = useState<WebcredIntegrationSteps>(WebcredIntegrationSteps.UNDEFINED);

    const { cessionToResume } = useCessionToResumeBco();
    const finalizeModalOpen = isFinalizeModalOpen && cessionToResume;
    const sendModalOpen = isSendModalOpen && cessionToResume;

    // TODO: ADJUST VARIABLES WITH CESSION
    const isSuccessCession = false;
    const hasCessionError = false;

    // TODO: WAIT FOR TOTALIZATION
    const allCessionOperationProcessed = false;
    const hasAnyCessionOperationFailed = false;
    const hasAnyCessionOperationProcessing = false;

    const determineFooterType = () => {
        if (isSuccessCession) return WebcredIntegrationSteps.CESSION_SUCCESSFUL;
        if (hasCessionError) return WebcredIntegrationSteps.FINALIZE_CESSION_FAILURE;
        if (allCessionOperationProcessed) return WebcredIntegrationSteps.FINALIZE_CESSION;
        if (hasAnyCessionOperationFailed) return WebcredIntegrationSteps.SEND_CESSION_OPERATION_FAILURE;
        if (hasAnyCessionOperationProcessing) return WebcredIntegrationSteps.SEND_CESSION_OPERATION_PROCESSING;

        return WebcredIntegrationSteps.SEND_CESSION_OPERATION;
    };

    useEffect(() => {
        setFooterType(determineFooterType());
    }, []);

    const generalFooterContent = (children: ReactNode): ReactNode => (
        <div className="cession-integration-footer">
            <div className="cession-integration-footer__button-box">{children}</div>
        </div>
    );

    const EmptyFooter: ReactNode = <></>;

    const SendCessionOperationFailureContent: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.error-message`)}</div>
    );

    const CessionSuccessfulContent: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.success-message`)}</div>
    );
    const FinalizeCessionFailureContent: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.stream-partial-message`)}</div>
    );

    const FinalizeCessionContent: ReactNode = generalFooterContent(
        <>
            <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.finalize-message`)}</div>
            <StandardButton
                label={t(`${I18N_PREFIX}.finalize`)}
                onClick={() => setIsFinalizeModalOpen(true)}
                disabled={!allCessionOperationProcessed}
            />
        </>
    );

    const SendCessionOperationProcessingContent: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.loading`)}</div>
    );

    const SendCessionOperationContent: ReactNode = generalFooterContent(
        <StandardButton
            label={t(`${I18N_PREFIX}.send`)}
            onClick={() => setIsSendModalOpen(true)}
            disabled={hasAnyCessionOperationProcessing}
        />
    );

    // TODO: ADJUST FOOTER CONTENT
    const renderFooterContent = {
        UNDEFINED: EmptyFooter,
        SEND_CESSION_OPERATION: SendCessionOperationContent,
        SEND_CESSION_OPERATION_PROCESSING: SendCessionOperationProcessingContent,
        SEND_CESSION_OPERATION_FAILURE: SendCessionOperationFailureContent,
        FINALIZE_CESSION: FinalizeCessionContent,
        FINALIZE_CESSION_FAILURE: FinalizeCessionFailureContent,
        CESSION_SUCCESSFUL: CessionSuccessfulContent,
    } as Record<WebcredIntegrationSteps, ReactNode>;

    const currentFooter = renderFooterContent[footerType];

    return { currentFooter, sendModalOpen, setIsSendModalOpen, finalizeModalOpen, setIsFinalizeModalOpen };
};
