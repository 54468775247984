import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import ClearButton from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleDatePickerInput from 'components/inputs/date-input/date-picker/simple-date-picker-input';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import {
    TransfeeraExternalTransfersFilterRequest,
    TransfeeraExternalTransfersStatus,
    defaultTransfeeraExternalTransfersFilter,
} from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/transfeera-external-transfers.model';
import { valuesOfObject } from 'shared/util/object-utils';

import './transfeera-transfers-filters-modal.scss';

export type TransfeeraExternalTransfersFilterType = 'filters' | 'date' | 'status' | 'search';

interface TransfeeraTransfersFitlersModalProps {
    onClose: () => void;
    modalFilter: TransfeeraExternalTransfersFilterType;
    openModal: boolean;
    title: string;
    filterActive: TransfeeraExternalTransfersFilterRequest;
    setFilterActive: (value: TransfeeraExternalTransfersFilterRequest) => void;
    setPage: (value: number) => void;
    resetInfiniteScrollListing: () => void;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-transfers.modal-filter';

export const DISABLED_MODAL_BUTTON_FUNCTION_RECORD = {
    filters: filter => valuesOfObject({ ...filter }).length === 0,
    date: filter => !filter.initialDate && !filter.endDate,
    status: filter => !filter.status,
    search: filter => !filter.search,
} as Record<TransfeeraExternalTransfersFilterType, (filter: TransfeeraExternalTransfersFilterRequest) => boolean>;

const TransfeeraTransfersFiltersModal = (props: TransfeeraTransfersFitlersModalProps) => {
    const { filterActive, modalFilter, onClose, openModal, setFilterActive, setPage, title, resetInfiniteScrollListing } = props;

    const { t } = useTranslation();

    const [filterRequest, setFilterRequest] = useState<TransfeeraExternalTransfersFilterRequest>(filterActive);

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    if (!openModal) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<TransfeeraExternalTransfersFilterRequest>) => {
        setFilterRequest(ps => ({ ...ps, ...value }));
    };

    const handleClearFilter = {
        filters: () => {
            resetInfiniteScrollListing();
            setFilterActive(defaultTransfeeraExternalTransfersFilter);
            setFilterRequest(defaultTransfeeraExternalTransfersFilter);
        },
        date: () => {
            resetInfiniteScrollListing();
            setFilterActive({
                ...filterActive,
                initialDate: defaultTransfeeraExternalTransfersFilter.initialDate,
                endDate: defaultTransfeeraExternalTransfersFilter.endDate,
            });
            setFilterRequest({
                ...filterRequest,
                initialDate: defaultTransfeeraExternalTransfersFilter.initialDate,
                endDate: defaultTransfeeraExternalTransfersFilter.endDate,
            });
        },
        status: () => {
            resetInfiniteScrollListing();
            setFilterActive({ ...filterActive, status: undefined });
            setFilterRequest({ ...filterRequest, status: undefined });
        },
        search: () => {
            resetInfiniteScrollListing();
            setFilterActive({ ...filterActive, search: undefined });
            setFilterRequest({ ...filterRequest, search: undefined });
        },
    } as Record<TransfeeraExternalTransfersFilterType, () => void>;

    const DateFilter: JSX.Element = (
        <>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.initialDate.label`)}
                    placeholder={t(`${I18N_PREFIX}.initialDate.placeholder`)}
                    value={filterRequest?.initialDate}
                    onChange={initialDate => handleChange({ initialDate })}
                    maxDate={filterRequest.endDate}
                    externalUpdate
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.endDate.label`)}
                    placeholder={t(`${I18N_PREFIX}.endDate.placeholder`)}
                    value={filterRequest?.endDate}
                    onChange={endDate => handleChange({ endDate })}
                    minDate={filterRequest.initialDate}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const StatusFilter: JSX.Element = (
        <>
            <div
                className={clsx('transfeera-transfers-modal-filter--status-label', {
                    CRIADA: filterRequest.status === TransfeeraExternalTransfersStatus.CRIADA,
                })}
                onClick={() => handleChange({ status: TransfeeraExternalTransfersStatus.CRIADA })}
            >
                {t(`${I18N_PREFIX}.status.CRIADA`).toUpperCase()}
            </div>
            <div
                className={clsx('transfeera-transfers-modal-filter--status-label', {
                    RECEBIDO: filterRequest.status === TransfeeraExternalTransfersStatus.RECEBIDO,
                })}
                onClick={() => handleChange({ status: TransfeeraExternalTransfersStatus.RECEBIDO })}
            >
                {t(`${I18N_PREFIX}.status.RECEBIDO`).toUpperCase()}
            </div>
            <div
                className={clsx('transfeera-transfers-modal-filter--status-label', {
                    TRANSFERIDO: filterRequest.status === TransfeeraExternalTransfersStatus.TRANSFERIDO,
                })}
                onClick={() => handleChange({ status: TransfeeraExternalTransfersStatus.TRANSFERIDO })}
            >
                {t(`${I18N_PREFIX}.status.TRANSFERIDO`).toUpperCase()}
            </div>
            <div
                className={clsx('transfeera-transfers-modal-filter--status-label', {
                    AGENDADO: filterRequest.status === TransfeeraExternalTransfersStatus.AGENDADO,
                })}
                onClick={() => handleChange({ status: TransfeeraExternalTransfersStatus.AGENDADO })}
            >
                {t(`${I18N_PREFIX}.status.AGENDADO`).toUpperCase()}
            </div>
            <div
                className={clsx('transfeera-transfers-modal-filter--status-label', {
                    FALHA: filterRequest.status === TransfeeraExternalTransfersStatus.FALHA,
                })}
                onClick={() => handleChange({ status: TransfeeraExternalTransfersStatus.FALHA })}
            >
                {t(`${I18N_PREFIX}.status.FALHA`).toUpperCase()}
            </div>
            <div
                className={clsx('transfeera-transfers-modal-filter--status-label', {
                    DEVOLVIDO: filterRequest.status === TransfeeraExternalTransfersStatus.DEVOLVIDO,
                })}
                onClick={() => handleChange({ status: TransfeeraExternalTransfersStatus.DEVOLVIDO })}
            >
                {t(`${I18N_PREFIX}.status.DEVOLVIDO`).toUpperCase()}
            </div>
        </>
    );

    const SearchFilter: JSX.Element = (
        <Grid item xs={12}>
            <SimpleSearchInput
                label={t(`${I18N_PREFIX}.search.label`)}
                placeholder={t(`${I18N_PREFIX}.search.placeholder`)}
                value={filterRequest.search}
                onChange={search => handleChange({ search })}
                externalUpdate
            />
        </Grid>
    );

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        openModal && (
            <Modal isOpen toggle={onClose}>
                <div className="transfeera-transfers-modal-filter">
                    <div className="transfeera-transfers-modal-filter--header">
                        <label className="transfeera-transfers-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {DateFilter}
                                            {SearchFilter}
                                            {StatusFilter}
                                        </>
                                    ),
                                    date: DateFilter,
                                    search: SearchFilter,
                                    status: StatusFilter,
                                } as Record<TransfeeraExternalTransfersFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>

                    <div className="transfeera-transfers-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default TransfeeraTransfersFiltersModal;
