import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import CessionIntegrationListHeader from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/components/cession-integration-header/cession-integration-list-header';
import CessionIntegrationCollapse from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/components/cession-integration-list/components/cession-integration-collapse/cession-integration-collapse';
import CessionIntegrationOptionsChunk from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/components/cession-integration-options-items/CessionIntegrationOptionsChunk';
import CessionIntegrationOptionsStream from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/components/cession-integration-options-items/CessionIntegrationOptionsStream';
import ModalGtecRetry from 'entities/batch/batch-outputs-detail/components/gtsec/modal-retry/modal-retry';
import ModalBatchExport from 'features/batch-export/components/modal/modal-batch-export';
import { ChunkStreamToResumeBco, DataChunk } from 'model/bco/batch-output-cession-integration-gtsec';
import { ChunkStreamStatus, DataChunkStatus, ExportRequestType } from 'model/bco/enums/batch-output-cession-integration-gtsec';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { chunkStreamRetryBcoResetState } from 'reducer-bco/batch-output/retry-chunk-stream/action';
import { dataChunkRetryBcoResetState } from 'reducer-bco/batch-output/retry-data-chunk/action';
import { useChunkStreamRetryBco, useDataChunkRetryBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import './cession-integration-list.scss';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-gtsec.with-partition.table';

interface CessionIntegrationListGtsecProps {
    chunkStreamToResumeBco: ChunkStreamToResumeBco;
    batchId: number;
    outputId: number;
}

const CessionIntegrationListGtsec: React.FC<CessionIntegrationListGtsecProps> = ({ chunkStreamToResumeBco, batchId, outputId }) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { toastLoading, closeToast, toastSuccess, toastError } = useToast();

    const { status: dataChunkStatus } = useDataChunkRetryBco();
    const { status: chunkStreamStatus } = useChunkStreamRetryBco();

    const [filterCollapse, setFilterCollapse] = useState<string[]>([]);
    const [openModalDetail, setOpenModalDetail] = useState(false);

    const handleSelect = (itemId: string) => {
        if (filterCollapse.includes(itemId)) {
            const newFilterCollapse = filterCollapse.filter(id => id !== itemId);
            setFilterCollapse(newFilterCollapse);
            return;
        }
        setFilterCollapse(filterCollapse => [...filterCollapse, itemId]);
    };

    const stream = chunkStreamToResumeBco?.stream;

    const [exportId, setExportId] = useState<number>();
    const [openModalRetry, setOpenModalRetry] = useState(false);
    const [selectDataChunk, setSelectDataChunk] = useState<DataChunk>();
    const [selectExportRequestType, setSelectExportRequestType] = useState<ExportRequestType>(ExportRequestType.CHUNK_INSTALLMENTS);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElSubTable, setAnchorElSubTable] = useState<null | HTMLElement>(null);

    const tbodyRef = useRef<HTMLTableSectionElement>(null);

    const resetScroll = () => {
        if (tbodyRef.current) {
            tbodyRef.current.scrollTop = 0;
        }
    };

    const isDisableStreamOptions: boolean = stream?.status === ChunkStreamStatus.SUCCESS || stream?.status === ChunkStreamStatus.PENDENT;
    const isDisableChunkOptions = (chunk: DataChunk): boolean => {
        return (
            chunk.status === DataChunkStatus.SUCCESS ||
            chunk.status === DataChunkStatus.PROCESSING ||
            chunk.status === DataChunkStatus.PENDENT
        );
    };

    useEffect(() => {
        if (dataChunkStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
            closeToast();
            return;
        }
        if (dataChunkStatus === HttpRequestStatus.ERROR) {
            toastError(t(`${I18N_PREFIX}.modal.retry.toast.send.error`));
            return;
        }
        if (dataChunkStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.modal.retry.toast.send.success`));
        }
    }, [dataChunkStatus, toastError, toastLoading, toastSuccess, t, dispatch]);

    useEffect(() => {
        if (chunkStreamStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
            closeToast();
            return;
        }
        if (chunkStreamStatus === HttpRequestStatus.ERROR) {
            toastError(t(`${I18N_PREFIX}.modal.retry.toast.close.error`));
            return;
        }
        if (chunkStreamStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.modal.retry.toast.close.success`));
        }
    }, [chunkStreamStatus, toastError, toastLoading, toastSuccess, t, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(dataChunkRetryBcoResetState());
            dispatch(chunkStreamRetryBcoResetState());
        };
    }, [dispatch]);

    return (
        <div className="cession-integration-list">
            {stream?.chunks.length ? (
                <table>
                    <CessionIntegrationListHeader />
                    {stream && (
                        <div className="cession-integration-list__closing-box">
                            <div className="cession-integration-list__closing">
                                <div className="cession-integration-list__closing-cell">
                                    <ExpandMoreIcon
                                        className={filterCollapse.includes(`stream-${stream.id}`) ? 'active' : ''}
                                        onClick={() => {
                                            handleSelect(`stream-${stream.id}`);
                                            resetScroll();
                                        }}
                                    />
                                </div>
                                <div className="cession-integration-list__closing-cell">{t(`${I18N_PREFIX}.stream.title`)}</div>
                                <div className="cession-integration-list__closing-cell">{stream.numberOfOperations}</div>
                                <div className="cession-integration-list__closing-cell">{stream.numberOfInstallments}</div>
                                <div className="cession-integration-list__closing-cell"></div>
                                <div className={clsx('cession-integration-list__closing-cell')}>
                                    <span
                                        className={clsx('cession-integration-list__closing-cell-status', {
                                            SUCCESS: stream.status === ChunkStreamStatus.SUCCESS,
                                            PARTIAL: stream.status === ChunkStreamStatus.PARTIAL,
                                            PENDENT: stream.status === ChunkStreamStatus.PENDENT,
                                            ERROR: stream.status === ChunkStreamStatus.ERROR,
                                        })}
                                    >
                                        {t(`${I18N_PREFIX}.stream.status.${stream.status}`)}
                                    </span>
                                </div>
                                <div className="cession-integration-list__closing-cell">
                                    <MoreOptionsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} disabled={isDisableStreamOptions}>
                                        <CessionIntegrationOptionsStream
                                            setSelectExportRequestType={setSelectExportRequestType}
                                            setOpenModal={setOpenModalRetry}
                                            setAnchorEl={setAnchorEl}
                                            stream={stream}
                                        />
                                    </MoreOptionsMenu>
                                </div>
                            </div>
                        </div>
                    )}
                    <tbody ref={tbodyRef} className="cession-integration-list__table-body">
                        <CessionIntegrationCollapse
                            filterCollapse={filterCollapse}
                            filterKey={`stream-${stream.id}`}
                            results={stream?.exports?.map(exportItem => exportItem.results)?.flat()}
                            setExportId={setExportId}
                            setOpenModalDetail={setOpenModalDetail}
                        />
                        {stream?.chunks.map(chunk => (
                            <>
                                <tr className="cession-integration-list__table-content" key={chunk.id}>
                                    <td>
                                        <ExpandMoreIcon
                                            className={filterCollapse.includes(chunk.id.toString()) ? 'active' : ''}
                                            onClick={() => handleSelect(chunk.id.toString())}
                                        />
                                    </td>
                                    <td>{chunk.index}</td>
                                    <td>{chunk.numberOfOperations}</td>
                                    <td>{chunk.numberOfInstallments}</td>
                                    <td></td>
                                    <td>
                                        <span
                                            className={clsx('cession-integration-list__table-content__chunk-status', {
                                                SUCCESS: chunk.status === DataChunkStatus.SUCCESS,
                                                PARTIAL: chunk.status === DataChunkStatus.PARTIAL,
                                                PENDENT: chunk.status === DataChunkStatus.PENDENT,
                                                PROCESSING: chunk.status === DataChunkStatus.PROCESSING,
                                                ERROR: chunk.status === DataChunkStatus.ERROR,
                                            })}
                                        >
                                            {t(`${I18N_PREFIX}.chunk.status.${chunk.status}`)}
                                        </span>
                                    </td>
                                    <td>
                                        <MoreOptionsMenu
                                            anchorEl={anchorElSubTable}
                                            setAnchorEl={setAnchorElSubTable}
                                            onClick={() => setSelectDataChunk(chunk)}
                                            disabled={isDisableChunkOptions(chunk)}
                                        >
                                            <CessionIntegrationOptionsChunk
                                                setSelectExportRequestType={setSelectExportRequestType}
                                                setOpenModal={setOpenModalRetry}
                                                setAnchorElSubTable={setAnchorElSubTable}
                                                chunk={chunk}
                                            />
                                        </MoreOptionsMenu>
                                    </td>
                                </tr>
                                <CessionIntegrationCollapse
                                    filterCollapse={filterCollapse}
                                    filterKey={chunk.id.toString()}
                                    results={chunk?.exports?.map(exportItem => exportItem.results)?.flat()}
                                    setExportId={setExportId}
                                    setOpenModalDetail={setOpenModalDetail}
                                />
                            </>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="cession-integration-list__error-message">
                    <span className="cession-integration-list__error-message-image" />
                    <span className="cession-integration-list__error-message-title">{t(`${I18N_PREFIX}.empty`)}</span>
                </div>
            )}

            {openModalDetail && (
                <ModalBatchExport batchId={batchId} exportId={exportId} openModal={openModalDetail} setOpenModal={setOpenModalDetail} />
            )}
            {openModalRetry && (
                <ModalGtecRetry
                    batchId={batchId}
                    outputId={outputId}
                    stream={stream}
                    chunk={selectDataChunk}
                    type={selectExportRequestType}
                    setOpenModal={setOpenModalRetry}
                />
            )}
        </div>
    );
};

export default CessionIntegrationListGtsec;
