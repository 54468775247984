import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import DateFormatter from 'components/formatter/date/date-formatter';
import FormatterUtils from 'components/formatter/formatter-utils';
import { PaymentTransactionFilterRequest } from 'model/payment-transaction';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

interface UseScdPaymentTransactionFilterChipsProps {
    filterActive: PaymentTransactionFilterRequest;
    setFilterActive: (value: PaymentTransactionFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.payment-transaction';

type ScdPaymentTransactionFilterRequestType = keyof Omit<PaymentTransactionFilterRequest, 'originatorId' | 'bankIdentification'>;

const useScdPaymentTransactionFilterChips = (props: UseScdPaymentTransactionFilterChipsProps) => {
    const { filterActive, setPage, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: PaymentTransactionFilterRequest) => {
            setFilterActive(value);
            setPage(0);
        },
        [setPage, setFilterActive]
    );

    const handleClearFilter: Record<ScdPaymentTransactionFilterRequestType, () => void> = useMemo(() => {
        return {
            date: () => clearFilterSupport({ ...filterActive, date: undefined }),
            favored: () => clearFilterSupport({ ...filterActive, favored: undefined }),
            originatorName: () => {
                clearFilterSupport({ ...filterActive, originatorName: undefined, originatorId: undefined });
            },
            status: () => clearFilterSupport({ ...filterActive, status: undefined }),
            mode: () => clearFilterSupport({ ...filterActive, mode: undefined }),
            dispatchMode: () => clearFilterSupport({ ...filterActive, dispatchMode: undefined }),
            amount: () => clearFilterSupport({ ...filterActive, amount: undefined }),
            type: () => clearFilterSupport({ ...filterActive, type: undefined }),
            bankName: () => {
                clearFilterSupport({ ...filterActive, bankName: undefined, bankIdentification: undefined });
            },
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: ScdPaymentTransactionFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.filter.buttons.${title}`);
            return `${titleTranslation}: `;
        },
        [t]
    );

    const scdPaymentTransactionFilterChips = useMemo(() => {
        const items: Record<ScdPaymentTransactionFilterRequestType, ChipFilterItem> = {
            originatorName: {
                isActive: !!filterActive.originatorName,
                onDelete: handleClearFilter['originatorName'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('originatorId')} component={filterActive.originatorName} />,
            },
            status: {
                isActive: !!filterActive.status,
                onDelete: handleClearFilter['status'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('status')}
                        component={t(`${I18N_PREFIX}.filter.input.status.options.${filterActive.status}`)}
                    />
                ),
            },
            favored: {
                isActive: !!filterActive.favored,
                onDelete: handleClearFilter['favored'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('favored')} component={filterActive.favored} />,
            },
            mode: {
                isActive: !!filterActive.mode,
                onDelete: handleClearFilter['mode'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('mode')}
                        component={t(`${I18N_PREFIX}.filter.input.mode.options.${filterActive.mode}`).toLocaleUpperCase()}
                    />
                ),
            },
            type: {
                isActive: !!filterActive.type,
                onDelete: handleClearFilter['type'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('type')}
                        component={t(`${I18N_PREFIX}.filter.input.type.options.${filterActive.type}`).toLocaleUpperCase()}
                    />
                ),
            },
            dispatchMode: {
                isActive: !!filterActive.dispatchMode,
                onDelete: handleClearFilter['dispatchMode'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('dispatchMode')}
                        component={t(`${I18N_PREFIX}.filter.input.dispatchMode.options.${filterActive.dispatchMode}`).toLocaleUpperCase()}
                    />
                ),
            },
            amount: {
                isActive: !!filterActive.amount,
                onDelete: handleClearFilter['amount'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('amount')}
                        component={[
                            FormatterUtils.formatNumberToCurrency(filterActive.amount?.minValue, filterActive.amount?.minValue, true),
                            FormatterUtils.formatNumberToCurrency(filterActive.amount?.maxValue, filterActive.amount?.maxValue, true),
                        ].join(' - ')}
                    />
                ),
            },
            date: {
                isActive: !!filterActive.date,
                onDelete: handleClearFilter['date'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('date')}
                        component={
                            <>
                                {filterActive.date?.start && <DateFormatter date={filterActive.date?.start} />}
                                {filterActive.date?.end && (
                                    <>
                                        {' - '}
                                        <DateFormatter date={filterActive.date?.end} />
                                    </>
                                )}
                            </>
                        }
                    />
                ),
            },
            bankName: {
                isActive: !!filterActive.bankName,
                onDelete: handleClearFilter['bankName'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('bankIdentification')} component={filterActive.bankName} />,
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle, t]);

    const isScdPaymentTransactionChipsActive: boolean = useMemo(() => {
        return scdPaymentTransactionFilterChips.some(it => !!it.isActive);
    }, [scdPaymentTransactionFilterChips]);

    return {
        scdPaymentTransactionFilterChips,
        isScdPaymentTransactionChipsActive,
    };
};

export default useScdPaymentTransactionFilterChips;
